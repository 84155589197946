var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component form-edit-component-large",
      attrs: { title: _vm.dialogTitle, visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        { staticClass: "import-excel-dialog-component" },
        [
          _c(
            "a-row",
            { staticClass: "ant-form-item" },
            [
              _c("a-col", { attrs: { span: 5 } }, [
                _c(
                  "div",
                  {
                    staticClass: "m-r-8",
                    staticStyle: {
                      "text-align": "right",
                      color: "rgba(0,0,0,0.85)",
                    },
                  },
                  [_vm._v("选择应用 :")]
                ),
              ]),
              _c("a-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "flex", staticStyle: { "flex-wrap": "wrap" } },
                  _vm._l(_vm.appIdList, function (item) {
                    return _c(
                      "div",
                      { key: item, staticClass: "app-group-batch-item flex" },
                      [
                        _c("div", [_vm._v(_vm._s(item.name))]),
                        _vm.appIdList.length > 1
                          ? _c("a-icon", {
                              staticClass: "pointer m-l-6",
                              staticStyle: { "font-size": "12px" },
                              attrs: { type: "close" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeAppId(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _c("jtl-form", { ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _c(
            "jtl-button",
            {
              attrs: { type: "primary", "click-prop": _vm.dialogOK.bind(this) },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }