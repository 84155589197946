





































































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { AppAuthEntityModel, AppGroupQueryModel } from '@common-src/entity-model/app-entity';
import { AppAssociateGroupService, updateAppSequence, AppGroupService, AppAuthService } from '@common-src/service3/app';
import SetGroupDialog from './set-group-dialog.vue';
import DeployAppDialog from './deploy-app-dialog.vue';
import GroupComponent from '@common-src/mixins/group-component';

const appService = new AppAuthService();

@Component({
    components: {
        'set-group-dialog': SetGroupDialog
    }
})
export default class AppListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    AppAuthEntityModel = AppAuthEntityModel;
    selectedRowKeys = [];
    groupList = [];
    created() {
        this.initTable({
            service: appService,
            queryModel: new AppGroupQueryModel(),
            tableColumns: AppAuthEntityModel.getTableColumns()
        });
        this.getList();
    }

    onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys;
    }

    batchDeploySetAppGroup() {
        if (_.isEmpty(this.selectedRowKeys)) {
            this.showMessage('请先选择需要分组的应用');
            return;
        }
        const data = _.filter(this.listData, (r: any) => this.selectedRowKeys.includes(r.id));
        (this.$refs.setGroupDialog as SetGroupDialog).dialogOpen(data);
    }
    changeList(groupIds) {
        let identifier = _.isEmpty(groupIds) ? null : groupIds[0];
        if (identifier && identifier.includes('&')) {
            identifier = _.last(_.split(identifier, '&'));
        }
        (this.queryModel as AppGroupQueryModel).groupIdentifier = identifier;
        this.getList();
    }
    changeSequence(record) {
        const param = {
            id: record.id,
            sequence: record.tempSequence || record.sequence
        };
        if (param.sequence === null) {
            this.getList();
            return;
        }
        updateAppSequence(param).then(res => {
            this.getList();
        });
    }

    treeSelect(groupIds: Array<string>) {
        if (_.isEmpty(groupIds)) {
            return;
        }
        let identifier = _.isEmpty(groupIds) ? null : groupIds[0];
        if (identifier && identifier.includes('&')) {
            identifier = _.last(_.split(identifier, '&'));
        }
        (this.queryModel as AppGroupQueryModel).groupIdentifier = identifier === 'all' ? null : identifier;
    }
}

